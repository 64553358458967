<template>
    <!-- 
    Loop through each bookmark and apply the container class -->
    <!-- Iterate over bookmarks if available, otherwise show a message -->
    <div>
        <div v-if="bookmarks && bookmarks.length > 0" class="side-effect-wrapper">
            <div v-for="item in bookmarks" :key="item.id" class="side-effect-card">
                <div class="bookmark-icon-container" @click="deleteBookmark(item.id)">
                    <i class="mdi mdi-bookmark bookmark-icon"></i>
                </div>
                <h3><span class="emoji">💊</span> {{ item.name }}</h3>
                <p><strong><span class="emoji"></span> Composition:</strong> {{ item.composition }}</p>
                <p><strong><span class="emoji"></span> Uses:</strong> {{ item.uses }}</p>
                <p><strong><span class="emoji">⚠️</span> Side Effects:</strong> {{ item.side_effects }}</p>
            </div>
        </div>
        <div v-else>
            <!-- Display message when there are no bookmarks -->
            <div id="noBookmarks">No bookmarks yet. Explore 
                <router-link to="/learn" id="learnIcon">
                    <a id="learnLink">Learn Homepage</a>
                </router-link> for details.
            </div>
        </div>
    </div>
</template>
<script>
    //
    // Import Axios for making HTTP requests.
    import axios from 'axios';
    //
    export default {
        data() {
            return {
                //
                // Initialize an empty array to store bookmarks.
                bookmarks: [],
            }
        },
        methods: {
            //
            async getBookmarks() {
                try {
                    //
                    //Call the endpoint to get the bookmarks.
                    const response = await axios.get('/api/learn/getBookmarks', { params: { type: 'side_effect' } });
                    //
                    //Assign the fetched bookmarks to the data property.
                    this.bookmarks = response.data.bookmarks;
                } catch (error) {
                    console.error('Error fetching bookmarks:', error);
                }
            },
            //
            // Method to extract the first 50 words from the description
            getFirst25Words(description) {
                //
                // Split the description into words
                const words = description.split(' ');
                //
                // Take the first 50 words and join them back together
                const first25Words = words.slice(0, 25).join(' ');
                return first25Words;
            },
            //
            //Save the learn details for easier access by user.
            async deleteBookmark(id) {
                //
                try {
                    //
                    // Fetch data based on id.
                    const response = await axios.get('api/learn/deleteBookmark', {
                        params: {
                            id: id, type: 'side_effect'
                        }
                    });
                    //
                    // Check if the deletion was successful
                    if (response.data.deleted) {
                        //
                        // Find the index of the deleted bookmark in the bookmarks array
                        const index = this.bookmarks.findIndex(bookmark => bookmark.id === id);
                        //
                        // Remove the bookmark from the array
                        if (index !== -1) {
                            this.bookmarks.splice(index, 1);
                        }
                    } else {
                        console.error('Failed to delete bookmark:', response.data.error);
                    }
                } catch (error) {
                    //
                    console.error('Error fetching learn data:', error);
                }
            },
        },
        mounted() {
            //
            // Call the method to fetch bookmarks when the component is mounted.
            this.getBookmarks();
        }
    }
</script>
<style scoped>
  .side-effect-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards horizontally */
  }

  .side-effect-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin: 2.5% 0 0 0;
    width: 90%; /* Adjust based on desired card width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Added to allow absolute positioning within */
  }

  .side-effect-card h3 {
    margin-top: 0;
  }

  .side-effect-card p {
    /* margin: 8px 0; */
  }

  .side-effect-card p strong {
    color: #555;
  }

  .bookmark-icon-container {
    position: absolute;
    top: 8px; /* Adjust as needed */
    right: 8px; /* Adjust as needed */
    cursor: pointer;
  }

  .bookmark-icon {
    font-size: 24px;
  }

  .emoji {
    font-size: 0.5em; /* Adjust the size as needed */
    vertical-align: middle; /* Align emojis vertically with the text */
  }
</style>