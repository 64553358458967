<template>

    <sidebar :page="'Learn'" :sidebar_data=sidebar_data></sidebar>

    <div class="backdrop"></div>

    <nav class="navbar bg-transparent p-0 pb-2">
    <div class="container-fluid">
        <a class="navbar-brand bars d-flex me-2 mt-2" @click="this.$emit('update:screen', this.screen - 1)">
        <em class="ph-arrow-left text-secondary me-2"></em>
        </a>

        <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">

        <notifications :user="user" :color_notifications_icons="'orange'" />

        <div class="divider mt-4 d-none"></div>
    </div>
    </nav>

    <div id="searchContainer" ref="searchContainer">
        <input type="text" id="searchInputField" @input="searchLearnDataFilter"
            placeholder="Search for test/treatment/condition.">
        <button id="searchBtn" class="btn">
            <font-awesome-icon :icon="['fa', 'search']" />
        </button>
        <router-link to="/learn" id="learnIcon">
            <i class="mdi mdi-book-open-page-variant"></i>
        </router-link>  
        <!-- 
        Dropdown container -->
        <div v-if="showDropdown" id="searchResultsDropdown">
            <ul>
                <li v-for="item in searchResults" :key="item.id" @click="selectItem(item.id)">
                {{ item.title }}
                </li>
            </ul>
        </div>
    </div>

    <h4 id="title">Bookmarks</h4>

    <nav class="tab-navigation">
      <button :class="{ active: currentPart === 'LearnPartBookmarks' }" @click="currentPart = 'LearnPartBookmarks'">Tests / Conditions</button>
      <button :class="{ active: currentPart === 'LearnSideEffectsBookmarks' }" @click="currentPart = 'LearnSideEffectsBookmarks'">Drugs / Side Effects</button>
      <button :class="{ active: currentPart === 'LearnWellnessBookmarks' }" @click="currentPart = 'LearnWellnessBookmarks'">Wellness Goals</button>
    </nav>
    <component :is="currentPart"></component>

    <floating-nav :page="'LearnBookmarks'"></floating-nav>
</template>

<script>
    import Notifications from '../components/Notifications'
    import FloatingNav from '../components/FloatingNav'
    import Sidebar from '../components/AutoSidebar'
    import LearnPartBookmarks from '../components/LearnPartBookmarks'
    import LearnSideEffectsBookmarks from '../components/LearnSideEffectsBookmarks'
    import LearnWellnessBookmarks from '../components/LearnWellnessBookmarks'
    //
    //Material design icon library.
    import '@mdi/font/css/materialdesignicons.min.css';
    //
    // Import Axios for making HTTP requests.
    import axios from 'axios';
    //
    export default {
        name: 'LearnBookmarks',
        components: {
            Notifications,
            FloatingNav,
            Sidebar,
            LearnPartBookmarks,
            LearnSideEffectsBookmarks,
            LearnWellnessBookmarks
        },
        data() {
            return {
                sidebar_data: [
                    { title: 'Home', link: '', if_children: false, icon: 'home', modal: false },
                    { title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false },
                ],
                //
                // Initialize an empty array to store bookmarks.
                bookmarks: [],
                //
                // Search query string
                searchQuery: '',
                searchResults: [],
                showDropdown: false,
                currentPart: 'LearnPartBookmarks',
                loading: false
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        mounted() {
            //
            // Add a global click event listener to handle clicks outside the dropdown
            document.addEventListener('click', this.handleClickOutside);
        },
        //
        beforeUnmount() {
            // Remove the click event listener when the component is destroyed
            document.removeEventListener('click', this.handleClickOutside);
        },
        methods: {
            //
            // Method to search learn data based on the search query, populate the data on a dropdown menu.
            async searchLearnDataFilter(event) {
                //
                //Get the search words from input field.
                this.searchQuery = event.target.value;
                //
                //Check if there is a search word in the input field, to determine if to show dropdown menu or not.
                if (!this.searchQuery) {
                    //
                    //If no word, hide, dropdown menu.
                    this.showDropdown = false;
                    return;
                }
                try {
                    this.loading = true;
                    //
                    //Get search results from database.
                    const response = await axios.get('api/learn/searchLearnDataFilter', {
                        params: {
                        query: this.searchQuery
                        }
                    });
                    //
                    //Save search results.
                    this.searchResults = response.data.data;
                    //
                    //Show dropdown menu.
                    this.showDropdown = true;
                } catch (error) {
                console.error('Error fetching learn data:', error);
                } finally {
                this.loading = false;
                }
            },
            //
            //Help the user select dropdown menu and open the learn details they prefer.
            selectItem(id) {
                //
                // Navigate to LearnDetails component with the selected item's id
                this.$router.push({ name: 'LearnDetails', params: { id: id } });
            },
            handleClickOutside(event) {
                //
                const container = this.$refs.searchContainer;
                //
                // Check if the clicked element is not inside the search container
                if (container && !container.contains(event.target)) {
                    //
                    // Clicked outside the dropdown, hide it
                    this.showDropdown = false;
                }
            }
        }
    }
</script>

<style>
    #title {
        text-align: center;
        font-weight: 600;
        font-size: 1.5rem;
        margin: 2.5% 0 4% 0;
    }

    #searchContainer {
    display: flex;
    justify-content: center;
    height: 6vh;
    position: relative; /* Ensure parent container has relative positioning */
    }

    #searchInputField {
    width: 70%;
    /* height: 120%; */
    border-radius: 1.2rem 0 0 1.2rem;
    }

    #searchResultsDropdown {
    position: absolute;
    top: 100%; /* Position dropdown below input field */
    left: 9%;
    width: 65%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    }

    #searchResultsDropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    }

    #searchResultsDropdown li {
    padding: 8px 16px;
    cursor: pointer;
    }

    #searchResultsDropdown li:hover {
    background-color: #f0f0f0;
    }


    #searchBtn {
        background: #1e3148;
        color: white;
        border-radius: 0 1.2rem 1.2rem 0;
    }
    #learnIcon{
        color: #1e3148;
        font-size: 1.7rem;
        margin: 1% 0 0 5%;
    }

    .bookmarksContainers {
        position: relative;
       margin: 3% 3% 3% 3%;
        /* 
            Border to create a container effect */
        border: 1px solid #f5f3f3;
        /* 
            Box shadow for a floating effect */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 0.5rem;
        transition: box-shadow 0.6s ease;
    }

    .bookmarksContainers:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 139, 0.2);
    }

    .bookmarkIconContainer {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0 20px 5px 5px;
    }

    .bookmarkIcon {
        color: #1e3148;
        font-size: 1rem;
    }

    .bookmarksTitle {
    color: #1a0dab;
    margin-left: 7%;
    }
    .bookmarksTitle:hover{
    cursor: pointer;
    text-decoration: underline;
    }

    .tagsContainers {
    font-style: italic;
    margin: 2% 0 1.5% 4%;
    }

    .tags {
    font-style: italic;
    font-size: small;
    background: #EDEADE;
    margin: 2% 0.5% 0 0.5%;
    padding: 0.6% 2% 0.6% 2%;
    border-radius: 2rem;
    }
    .tags:hover{
    cursor: pointer;
    }
    #noBookmarks{
        margin: 20% 5% 0 5%;
        font-size: 1.2rem;
        color: #1e3148;
    }
    #learnLink{
        font-size: 1rem;
        font-style: italic;
        margin: 0 1% 0 0;
        color: blue;
    }
    .tab-navigation {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    }

    .tab-navigation button {
    border: 1px solid #ccc;
    background-color: transparent;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    border-radius: 20px;
    margin-right: 10px; /* Add margin between buttons */
    }

    .tab-navigation button.active {
    background-color: #e0e0e0; /* Change to your desired active color */
    color: #333; /* Change to your desired text color for active state */
    border-color: #e0e0e0; /* Change to your desired border color for active state */
    }
</style>